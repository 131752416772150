const videos = document.querySelectorAll('.js__vimeo')

videos.forEach((wrapper) => {
    var player = wrapper.querySelector('.js__vimeo__video') || new window.Vimeo.Player(wrapper.querySelector('.js__vimeo__iframe'))
    var playPauseButton = wrapper.querySelector('.js__vimeo__button')
    var prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)')

    if ( prefersReducedMotion && prefersReducedMotion.matches ) {
        player.pause()
    }

    function videoEvent(event, callback) {
        if (typeof player.addEventListener === 'function') {
            player.addEventListener(event, callback) // Native element.
        } else {
            player.on(event, callback) // Vimeo embed.
        }
    }

    videoEvent('play', function() {
        wrapper.classList.add('video--playing')
    })

    videoEvent('pause', function() {
        wrapper.classList.remove('video--playing')
    })

    if (playPauseButton) {
        const pauseLabel = playPauseButton.dataset.pauseLabel
        const playLabel = playPauseButton.dataset.playLabel

        if (playLabel) {
            playPauseButton.ariaLabel = playLabel
            videoEvent('pause', function() {
                playPauseButton.ariaLabel = playLabel
            })
        }

        if (pauseLabel) {
            videoEvent('play', function() {
                playPauseButton.ariaLabel = pauseLabel
            })
        }

        playPauseButton.addEventListener('click', function() {
            if (typeof player.paused === 'boolean') { // Native.
                player.paused ? player.play() : player.pause()
            } else {
                player.getPaused().then(function(paused) { // Vimeo.
                    if (paused) {
                        player.play().then(function() {
                            wrapper.classList.add('video--playing')
                        })
                    } else {
                        player.pause().then(function() {
                            wrapper.classList.remove('video--playing')
                        })
                    }
                })
            }
        })
    }
})
